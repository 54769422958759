<template lang="pug">
  v-app
    top-bar
    v-main
      router-view
    leftMobileMenu
</template>

<script>
import HelloWorld from '@/components/home/topSection.vue'
import topBar from '@/layout/topBar/topBar.vue'
import leftMobileMenu from '@/layout/leftSide/leftMobileMenu.vue'

export default {
  name: 'App',
  components: {
    topBar,
    HelloWorld,
    leftMobileMenu
  },
  head: {
    link: [
      {
        rel: 'icon',
        // eslint-disable-next-line global-require
        href: require('./assets/logo.svg')
      }
    ]
  }
}
</script>
