<template lang="pug">
  v-navigation-drawer(
    :value="$store.getters['app/getOpenedMobileMenu']"
    @input="(val) => $store.commit('app/setOpenedMobileMenu', val)"
    app
    temporary
    height="100%"
    :width="$vuetify.breakpoint.xs ? '100%' : 256"
  )
    v-list-item
      v-list-item-avatar(rounded="0")
        img(class="logo" src="@/assets/logos/1.svg" alt="КДВС")
      v-list-item-content
        v-list-item-title Меню
      v-list-item-action
        v-btn(icon @click="$store.commit('app/setOpenedMobileMenu', false)")
          v-icon mdi-close
    v-divider
    v-list(dense)
      v-list-item(v-for='item in items' :key='item.label' @click="clickItem(item.link)")
        v-list-item-content
          v-list-item-title {{ item.label }}
      v-list-item
        excel-btn
</template>

<script>
import useNavigation from '@/composition/navigation'
import ExcelBtn from '@/components/ui/excel-btn.vue'

export default {
  name: 'leftMobileMenu',
  components: { ExcelBtn },
  setup(props, { root }) {
    const { items, options } = useNavigation()

    const clickItem = (itemLink) => {
      root.$store.commit('app/setOpenedMobileMenu', false)
      root.$vuetify.goTo(itemLink, options)
    }

    return { items, options, clickItem }
  }
}
</script>
