<template lang="pug">
  v-btn(depressed color="teal lighten-3" width="220" height="36" href="catalog.xlsx" download)
    v-row(justify="start")
      v-col(cols="2" class="d-flex justify-start align-center")
        v-icon(right dark)  mdi-cloud-upload
      v-col(cols="10")
        p(class="text-left secondary--text mb-0" style="font-size: 0.75rem")
          | Скачать перечень
          br
          | продаваемых позиций
</template>

<script>
export default {
  name: 'excel-btn'
}
</script>

<style scoped>

</style>
