import { reactive, ref } from '@vue/composition-api'

export default function useNavigation() {
  const contactLink = ref('#contacts')
  return {
    items: ref([
      { label: 'Главная', link: '#main' },
      { label: 'Мы поставляем', link: '#examples' },
      { label: 'Аутсорсинг снабжения', link: '#outsource' },
      { label: 'Почему мы', link: '#whyWe' },
      { label: 'Контакты', link: contactLink.value }
    ]),
    options: reactive({
      duration: 700,
      offset: 0,
      easing: 'easeInOutCubic'
    }),
    contactLink: contactLink.value
  }
}
