<template lang="pug">
  v-container(fluid class="contactsSection px-0 py-5")
    v-container(class="pb-5")
      v-row(no-gutters :class="['py-15', $vuetify.breakpoint.mdAndUp ? 'justify-between' : 'justify-center']")
        v-col(cols="6" v-if="$vuetify.breakpoint.mdAndUp")
          v-img(:src="contactImage" height="250px" max-width="100%" contain)
        v-col(cols="12" sm="6")
          v-row(no-gutters)
            h2(class="white--text") КОНТАКТНАЯ ИНФОРМАЦИЯ
          v-row(no-gutters class="mt-5")
            h3(class="contactsSection__desc") Свяжитесь с нами удобным для Вас способом, будем рады сотрудничеству!
          v-row(no-gutters class="mt-5")
            v-divider(color="eb9b04" style="height: 2px; width: 50px;")
          v-row(no-gutters class="mt-5")
            v-col(cols="1")
              v-icon(color="primary") mdi-phone-in-talk-outline
            v-col(cols="11" class="pl-3")
              v-row(no-gutters)
                span(class="contactsSection__desc") Звоните по номеру:
              v-row(no-gutters class="mt-2")
                div.contactsSection__itemNumber-wrap
                  a(class="contactsSection__itemNumber" href="tel:+79644540800") +7(964)454-08-00
                div.contactsSection__itemNumber-wrap
                  a(class="contactsSection__itemNumber contactsSection__itemNumberSecond" href="tel:+79644540900")  +7(964)454-09-00
          v-row(no-gutters class="mt-5")
            v-col(cols="1")
              v-icon(color="primary") mdi-email
            v-col(cols="11" class="pl-3")
              v-row(no-gutters)
                span(class="contactsSection__desc") Пишите по email:
              v-row(no-gutters class="mt-2")
                a(class="contactsSection__itemNumber" href="mailto:kdvsdir@gmail.com") kdvsdir@gmail.com
      v-row(no-gutters class="justify-center mt-5 pt-5")
        span(class="contactsSection__desc text-caption") Copyright © 2021-2022 КДВС
</template>

<script>
import contactImage from '@/assets/contacts/1.svg'

export default {
  name: 'contactsSections',
  setup() {
    return { contactImage }
  }
}
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.contactsSection
  background: #212121
  &__desc
    color: #bebebe
    font-weight: normal
  &__itemNumber-wrap
    display: inline-block
    @media #{map-get($display-breakpoints, 'sm-and-down')}
      display: block
      width: 100%
      margin-bottom: 5px
  a.contactsSection__itemNumber
    color: #212121
    padding: 1px 8px
    text-decoration: none
    font-weight: bold
    background: #fff
    border-radius: 10px
    display: inline-block
    height: 25px
    &:hover
      color: #eb9b04
      transition: color 500ms
  a.contactsSection__itemNumberSecond
    margin-left: 15px
    @media #{map-get($display-breakpoints, 'sm-and-down')}
      margin-left: 0
</style>
