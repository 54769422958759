<template lang="pug">
  v-col(cols="12" sm="4" class="pa-5")
    v-row(no-gutters justify="start" )
      v-img(:src="reason.imgSrc" max-width="50%" height="100px" contain)
    v-row(no-gutters class="mt-2")
      h4 {{ reason.label }}
    v-row(no-gutters class="mt-3")
      h5(class="font-weight-regular") {{ reason.desc }}
</template>

<script>
export default {
  name: 'whyCard',
  props: ['reason']
}
</script>

<style lang="sass">

</style>
