<template lang="pug">
  v-container(fluid class="px-0 py-5")
    v-container(class="outsourceSection")
      v-row(class="justify-between py-5")
        v-col(cols="12" md="6" class="outsourceSection__imageBlock pa-0" v-if="$vuetify.breakpoint.mdAndUp")
          v-img(
            max-width="100%"
            :src="image"
          )
        v-col(cols="12" md="6" class="outsourceSection__textBlock")
          v-row(no-gutters)
            v-col(cols="1" class="align-self-start justify-center pa-2")
              v-row(no-gutters class="justify-center align-center")
                span(class="aboutMe_vertical secondary--text text--lighten-4 text-caption") outsource
              v-row(no-gutters class="justify-center")
                v-divider(vertical inset style="height: 50px;" color="white")
            v-col(cols="11")
              h2(class="font-weight-bold primary--text mb-3")
                | Аутсорсинг снабжения –
                br
                | закупаем для Вас.
              ul(class="outsource-list text-caption white--text")
                li Берём на себя обязательства в организации закупок в соответствии с Вашими запросами.
                li Содержание штата специалистов снабжения за наш счёт
                li Используем наработанную годами базу поставщиков под любые объёмы и виды запросов
                li Просто скажите, что Вам нужно закупить, мы закроем Вашу потребность по лучшей цене\сроку
                li Все сделки максимально прозрачны
              v-row(class="mt-5" no-gutters)
                v-btn(depressed color="primary" max-width="100%" small width="150px" class="ml-5" @click="$vuetify.goTo(contactLink, options)")
                  span(class="onPrimary--text") Узнать подробнее
</template>

<script>
import socials from '@/components/ui/socials.vue'
import image from '@/assets/outsource/bg.jpg'
import useNavigation from '@/composition/navigation';

export default {
  name: 'aboutMeSection',
  components: {
    socials
  },
  setup() {
    // navigator
    const { contactLink, options } = useNavigation()
    return { image, contactLink, options }
  }
}
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.outsourceSection
  &__textBlock
    background: #212121
    padding: 40px
    margin-top: 70px
    @media #{map-get($display-breakpoints, 'sm-and-down')}
      margin-top: 0
    h2
      font-size: 2.125rem !important
      font-weight: 400
      line-height: 2.5rem
      letter-spacing: 0.0073529412em !important
      @media #{map-get($display-breakpoints, 'xs-only')}
        font-size: 1.1rem !important
        line-height: 1.5rem
      @media #{map-get($display-breakpoints, 'sm-only')}
        font-size: 1.8rem !important
        line-height: 2.1rem
    ul.outsource-list > li
      line-height: 1.45em
      & + li
        margin-top: 5px
.aboutMe_vertical
  writing-mode: vertical-rl
  transform: rotate(180deg)
</style>
