<template lang="pug">
  fragment
    v-btn(icon small color="secondary")
      v-icon(v-text="'$telegram'" small)
    v-btn(icon small color="secondary" class="ml-1")
      v-icon(v-text="'$whatsapp'" small)
    v-btn(icon small color="secondary" class="ml-1")
      v-icon(v-text="'$vk'" small)
    v-btn(icon small color="secondary" class="ml-1")
      v-icon(v-text="'$github'" small)
</template>

<script>
export default {
  name: 'socials'
}
</script>
