<template lang="pug">
  v-app-bar(app color="surface" dense elevation='6' class="px-0" elevate-on-scroll height="55" )
    v-container(class="pa-0 fill-height")
      v-row(align="center" justify="space-between" no-gutters)
        v-col(cols="2" class="align-center")
          img(class="logo" src="@/assets/logos/1.svg" alt="КДВС")
        v-col(cols="1" md="6" v-if="!$vuetify.breakpoint.mobile")
          horizontalNav
        v-spacer
        v-col(cols="4" v-if="!$vuetify.breakpoint.mobile")
          v-row(no-gutters justify="end" align="center")
            excel-btn
        v-app-bar-nav-icon(v-if="$vuetify.breakpoint.mobile" @click.stop="$store.commit('app/toggleOpenedMobileMenu')")
</template>

<script>
import horizontalNav from '@/layout/topBar/horizontalNav.vue'
import ExcelBtn from '@/components/ui/excel-btn.vue';

export default {
  name: 'topBar.vue',
  components: {
    ExcelBtn,
    horizontalNav
  }
}
</script>

<style lang="scss">
img.logo {
  display: block;
  height: 45px;
}
</style>
