<template lang="pug">
  v-container(fluid class="topSection bg-1 overlay-1 relative")
    v-container(class="relative")
      v-row(class="justify-between" :style="{ height: sectionHeight }" align="center")
        v-col(cols="12" sm="6" offset-sm="2" )
          h1(class="topSection__ttl font-weight-bold mb-3 white--text text-shadow-black-2")
            | Комплексное Дальневосточное Снабжение
            span(class="secondary--text text--lighten-5 ml-4") |
          p(class="topSection__subtitle grey--text text--lighten-4 text-shadow-black-2")
            | Мы занимаемся комплексным снабжением строительных объектов и предприятий. Опыт на рынке более 6 лет.
          v-row(class="mt-5" no-gutters)
              excel-btn(:class="['mr-3', $vuetify.breakpoint.smAndDown ? 'mb-3' : '']")
              v-btn(depressed color="primary" max-width="100%" height="36" @click="$vuetify.goTo(contactLink, options)")
                span(class="onPrimary--text") Связаться с нами

</template>

<script>
import { computed } from '@vue/composition-api'
import socials from '@/components/ui/socials.vue'
import ExcelBtn from '@/components/ui/excel-btn.vue';
import useNavigation from '@/composition/navigation';

export default {
  name: 'topSection',
  components: {
    ExcelBtn,
    socials
  },
  setup(props, ctx) {
    // section height
    const sectionHeight = computed(() => `${ctx.root.$vuetify.breakpoint.height - ctx.root.$vuetify.application.top - 2}px`)

    // navigator
    const { contactLink, options } = useNavigation()

    return { sectionHeight, contactLink, options }
  }
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

  .bg-1 {
    background: url('~assets/bg.jpg') no-repeat center 33%;
    background-size: cover;
  }
  .overlay-1:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.65);
  }
  .relative {
    position: relative;
  }
  .text-shadow-black-2 {
    text-shadow: 2px 2px black;
  }
  .topSection {
    padding: 0;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 20px;
    }
    &__ttl {
      font-size: 3em;
      font-weight: bold;
      line-height: 1.3;
      @media #{map-get($display-breakpoints, 'sm-only')} {
        font-size: 2em
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1.5em
      }
    }
    &__subtitle {
      font-size: 1.2em;
      font-weight: bold;
      line-height: 1.3;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 1em;
        font-weight: normal;
      }
    }
  }
</style>
