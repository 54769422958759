<template lang="pug">
  fragment
    topSection(id="main" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    examplesSection(id="examples" v-intersect="{ handler: onIntersect, options: { threshold: 0.5 } }")
    outsourceSection(id="outsource" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    whyWeSection(id="whyWe" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
    contactsSection(id="contacts" v-intersect="{ handler: onIntersect, options: { threshold: 0.8 } }")
</template>

<script>
import topSection from '@/components/home/topSection.vue'
import examplesSection from '@/components/home/examplesSection.vue'
import outsourceSection from '@/components/home/outsourceSection.vue'
import whyWeSection from '@/components/home/whyWeSection.vue'
import contactsSection from '@/components/home/contactsSection.vue'

export default {
  name: 'Home',
  components: {
    topSection,
    examplesSection,
    outsourceSection,
    whyWeSection,
    contactsSection
  },
  setup(props, { root }) {
    const onIntersect = (entries) => {
      const sectionId = entries[0].target.id
      root.$router.replace({ name: root.$route.name, hash: `#${sectionId}` })
    }
    return {
      onIntersect
    }
  }
}
</script>
