<template lang="pug">
  v-container(fluid class="px-0 py-5")
    v-container(class="pb-5")
      v-row(no-gutters class="justify-between py-5" align="center")
        v-col(cols="12" sm="12" md="11" offset-md="1")
          // HEADER секции
          v-row(no-gutters class="justify-center mb-5" align="center")
            // Вертикальный тег
            v-col(cols="auto" class="align-self-start justify-center pa-2")
              v-row(no-gutters class="justify-center align-center")
                span(class="examplesSection_vertical secondary--text text--lighten-4 text-caption") supply
              v-row(no-gutters class="justify-center")
                v-divider(vertical inset style="height: 50px")
            // Заголовок секции, описание и навигация по слайдеру
            v-col(cols="10" md="9")
              h2(class="display-1 font-weight-bold mb-3")
                | Мы поставляем
              v-row(no-gutters )
                v-col(cols="12" sm="6")
                  v-row(no-gutters v-for="(example, index) in examples.slice(0, 5)" :key="index" class="align-center text-caption primary--text mb-2")
                    span {{ index + 1 }}.
                    span(v-if="$vuetify.breakpoint.xsOnly" class="text--secondary ml-2 font-weight-bold") {{ example.label }}
                    v-btn(v-if="$vuetify.breakpoint.smAndUp" class="examplesSection__slider-navBtn" color="secondary" text small :input-value="activeSlide === index" :class="['px-1 ml-2']" @click="toSlide(index)")
                      | {{ example.label }}
                v-col(cols="12" sm="6")
                  v-row(no-gutters v-for="(example, index) in examples.slice(5, 10)" :key="index" class="align-center text-caption primary--text mb-2")
                    span {{ index + 6 }}.
                    span(v-if="$vuetify.breakpoint.xsOnly" class="text--secondary ml-2 font-weight-bold") {{ example.label }}
                    v-btn(v-if="$vuetify.breakpoint.smAndUp" class="examplesSection__slider-navBtn" color="secondary" text small :input-value="activeSlide === (index + 5)" :class="['px-1 ml-2']" @click="toSlide(index + 5)")
                      | {{ example.label }}
              v-row(no-gutters class="align-center text-caption black--text mt-3 mb-3")
                span(class="font-weight-bold ") И многое другое, более 40 тысяч наименований поставляемых позиций
              v-row(no-gutters justify="center" v-if="$vuetify.breakpoint.xsOnly")
                v-btn(depressed color="primary" max-width="100%" small width="150px" class="mt-3" @click="$vuetify.goTo(contactLink, options)")
                  span(class="onPrimary--text") Оставить заявку
          // Слайдер
          v-row(no-gutters v-if="$vuetify.breakpoint.smAndUp")
            v-col(cols="12" md="9" offset-md="1" )
              v-row(no-gutters class="justify-center align-center")
                // Кнопка переключения слайдера влево
                v-col(cols="1" class="align-center" v-if="$vuetify.breakpoint.smAndUp")
                  v-btn(class="mx-2 button-prev" fab dark small color="primary" slot="button-prev")
                    v-icon(dark) mdi-chevron-left
                // Контент слайдера
                v-col(cols="12" sm="10" class="align-center")
                  v-row(no-gutters)
                    swiper(
                      ref="mySwiper"
                      :options="swiperOptions"
                      :style="swiperStyle"
                      class="examplesSwiper"
                      @slideChange="handleChangeSlide"
                    )
                      swiper-slide(v-for="(example, index) in examples" :key="index" class="align-self-center")
                        v-row(no-gutters style="max-width: 100%" justify="center" )
                          .examplesSwiper-card
                            v-row(no-gutters :class="['elevation-3 align-self-center ma-2', $vuetify.theme.isDark ? 'background' : 'grey lighten-5']")
                              v-col(cols="auto")
                                v-img(
                                  :width="$vuetify.breakpoint.sm ? 150 : 250"
                                  :height="$vuetify.breakpoint.sm ? 150 : 250"
                                  :src="example.imgSrc"
                                )
                              v-col(class="pl-4 pr-5 pt-3 pb-3 d-flex flex-column justify-center align-center")
                                h3(class="examplesSwiper-card__ttl") {{ example.fullName }}
                                v-btn(depressed color="primary" max-width="100%" small width="150px" class="mt-3" @click="$vuetify.goTo(contactLink, options)")
                                  span(class="onPrimary--text") Оставить заявку
                      .swiper-pagination.myPagination(slot="pagination")
                // Кнопка переключения слайдера вправо
                v-col(cols="1" class="align-center" v-if="$vuetify.breakpoint.smAndUp")
                  v-btn(class="mx-2 button-next" fab dark small color="primary" slot="button-next")
                    v-icon(dark) mdi-chevron-right

</template>

<script>
/* eslint-disable global-require */
import {
  ref, computed, reactive
} from '@vue/composition-api'
import { Swiper as SwiperClass, Navigation } from 'swiper/js/swiper.esm'
import useNavigation from '@/composition/navigation';

SwiperClass.use([Navigation])
export default {
  name: 'skillsSection',
  setup(props, { root }) {
    // examples
    const examples = computed(() => [
      {
        label: 'Каучуковая изоляция',
        fullName: 'Каучуковая изоляция от производителя "РуФлекс"',
        imgSrc: require('@/assets/catalog/ruflex.jpg')
      },
      {
        label: 'Электротехника',
        fullName: 'Электротехника от производителя "TDM"',
        imgSrc: require('@/assets/catalog/tdm.jpg')
      },
      {
        label: 'Кабельная продукция',
        fullName: 'Кабельная продукция',
        imgSrc: require('@/assets/catalog/kabel.jpg')
      },
      {
        label: 'Сантехника и канализация',
        fullName: 'Сантехника и канализация от производителей Danfoss, Wavin, Ferat и другие',
        imgSrc: require('@/assets/catalog/santeh.jpg')
      },
      {
        label: 'Трубопроводная и запорная арматура',
        fullName: 'Трубопроводная и запорная арматура',
        imgSrc: require('@/assets/catalog/armatura.jpg')
      },
      {
        label: 'Крепеж и метизы',
        fullName: 'Крепеж и метизы, а также прокладки паронит, СНП, МБС',
        imgSrc: require('@/assets/catalog/krepezh.jpg')
      },
      {
        label: 'Сэндвич-панели',
        fullName: 'Сэндвич-панели, профнастил и доборные элементы',
        imgSrc: require('@/assets/catalog/sandwich.jpg')
      },
      {
        label: 'Вентиляция',
        fullName: 'Вентиляция и вентиляционное оборудование',
        imgSrc: require('@/assets/catalog/ventil.jpg')
      },
      {
        label: 'Инструменты',
        fullName: 'Инструменты, хоз. инветарь, оснастка',
        imgSrc: require('@/assets/catalog/instrument.jpg')
      },
      {
        label: 'Сухие строительные смеси',
        fullName: 'Сухие строительные смеси, а также лакокрасочные материалы',
        imgSrc: require('@/assets/catalog/smesi.jpg')
      }
    ])
    // swiper
    const mySwiper = ref(null)
    const swiperOptions = reactive({
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
        disabledClass: 'd-none'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletActiveClass: 'swiper-pagination-bullet-active primary'
      },
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false
      },
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: '1',
      autoHeight: true,
      spaceBetween: 30
    })
    const swiperInstance = computed(() => mySwiper.value?.$swiper)
    const toSlide = (index) => {
      mySwiper.value.$swiper.slideTo(index, 300)
    }
    const activeSlide = ref(0)
    const handleChangeSlide = () => {
      activeSlide.value = swiperInstance.value?.activeIndex
    }
    const swiperStyle = computed(() => ({ height: root.$vuetify.breakpoint.sm ? '270px' : '350px', 'padding-top': '45px' }))

    // navigator
    const { contactLink, options } = useNavigation()

    return {
      examples, swiperOptions, mySwiper, toSlide, swiperInstance, handleChangeSlide, activeSlide, swiperStyle, contactLink, options
    }
  }
}
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
.examplesSection
  &_vertical
    writing-mode: vertical-rl
    transform: rotate(180deg)
  &__slider-navBtn
    .v-btn__content
      max-width: 100%
      white-space: pre-wrap
      text-align: left
      @media #{map-get($display-breakpoints, 'sm-and-down')}
        max-width: 205px
.examplesSwiper > .swiper-wrapper > .row + .row
  margin-top: -12px
.examplesSwiper .myPagination
  bottom: 0!important
.examplesSwiper-card
  width: 650px
  @media #{map-get($display-breakpoints, 'sm-only')}
    max-width: 450px
  &__ttl
    font-weight: bold
    font-size: 1.2em
    text-align: center
    @media #{map-get($display-breakpoints, 'sm-only')}
      font-size: 1em
      max-width: 220px
</style>
