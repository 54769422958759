<template lang="pug">
  v-tabs(
    height="30"
    :show-arrows="false"
    background-color="surface"
    slider-color="primary"
    color="primary"
    slider-size="1"
  )
    v-tab(
      v-for="(item, index) in items"
      :key="index"
      :ripple="false"
      :to="item.link"
      @click="$vuetify.goTo(item.link, options)"
    ) {{ item.label }}
</template>

<script>
import useNavigation from '@/composition/navigation'

export default {
  name: 'horizontalNav',
  setup() {
    const { items, options } = useNavigation()
    return { items, options }
  }
}
</script>

<style scoped>

</style>
