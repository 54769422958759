<template lang="pug">
  v-container(fluid class="px-0 py-5")
    v-container(class="pb-5")
      v-row(no-gutters class="justify-between py-5" align="center")
        h2 Почему мы
      v-divider()
      v-row(no-gutters)
        whyCard(v-for="(reason, index) in reasons" :key="index" :reason="reason")
</template>

<script>
/* eslint-disable global-require */
import { ref } from '@vue/composition-api'
import whyCard from '@/components/home/whyWe/whyCard.vue';

export default {
  name: 'skillsSection',
  components: {
    whyCard
  },
  setup() {
    const reasons = ref([
      {
        label: 'Работа напрямую с заводами',
        desc: 'Мы работаем исключительно с производителями поставляемой продукции, исключая звено всевозможных посредников',
        imgSrc: require('@/assets/whyWe/1.svg')
      },
      {
        label: 'Гибкая ценовая политика',
        desc: 'Мы готовы к диалогу с любым заказчиком, чтобы предоставить лучшую цену на рынке',
        imgSrc: require('@/assets/whyWe/2.svg')
      },
      {
        label: 'Гарантия качества',
        desc: 'На все виды продукции имеются сертификаты и паспорта качества',
        imgSrc: require('@/assets/whyWe/3.svg')
      },
      {
        label: 'Доставка по всей стране',
        desc: 'Мы можем осуществить доставку до объекта заказчика, а также мы готовы отгрузить товар любой удобной заказчику транспортной компанией',
        imgSrc: require('@/assets/whyWe/4.svg')
      },
      {
        label: 'Квалифицированный специалисты',
        desc: 'Для удобной и комфортной работы мы закрепим персонального менеджера за каждым заказчиком',
        imgSrc: require('@/assets/whyWe/5.svg')
      },
      {
        label: 'Большой выбор продукции',
        desc: 'Более 40 000 наименований поставляемой продукции',
        imgSrc: require('@/assets/whyWe/6.svg')
      }
    ])
    return { reasons }
  }
}
</script>
