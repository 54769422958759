export default function SET_APP(state, payload) {
  state.app = payload
}

export function toggleOpenedMobileMenu(state) {
  state.app.openedMobileMenu = !state.app.openedMobileMenu;
}
export function setOpenedMobileMenu(state, payload) {
  state.app.openedMobileMenu = payload;
}
