import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/src/locale/ru.ts'
import '@/styles/overrides.sass'
import Fragment from 'vue-fragment'

Vue.use(Fragment.Plugin)
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'mdi',
    values: {
      close: 'mdi-close',
      menu: 'mdi-menu',
      telegram: 'mdi-telegram',
      whatsapp: 'mdi-whatsapp',
      vk: 'mdi-vk',
      github: 'mdi-github'
    }
  },
  theme: {
    options: {
      // чтобы исопльзовать переменные в styles
      customProperties: true
    },
    themes: {
      light: {
        primary: '#EB9B04',
        secondary: '#424242',
        background: '#fff',
        surface: '#fff',
        error: '#FF5252',
        onPrimary: '#fff',
        onSecondary: '#fff',
        onBackground: '#000000',
        onSurface: '#000000',
        onError: '#fff'
      },
      dark: {
        primary: '#EB9B04',
        secondary: '#e5e5e5',
        background: '#424242',
        surface: '#424242',
        error: '#502525',
        onPrimary: '#000000',
        onSecondary: '#000',
        onBackground: '#fff',
        onSurface: '#fff',
        onError: '#fff'
      }
    }
  }
})
